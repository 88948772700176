<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="is-hidden-desktop is-hidden-widescreen">
        <Mobilemenu/>
      </div>
      <div class="columns">
        <div
            class="column is-narrow-desktop is-hidden-mobile is-hidden-touch sbar"
        >
          <Sidebar/>
        </div>
        <div v-if="loaded" class="column maincolumn lessonContainer">
          <div class="is-full-touch is-full-mobile" v-if="activeSub">
            <div class="toolbar">
              <div v-if="admin || staff" class="field is-grouped">
                <router-link
                    :to="{ name: 'KozHubVideosAddVideo' }"
                    class="button is-success"
                >Create Video
                </router-link
                >
              </div>
            </div>
          </div>
          <div class="block">
            <div class="lesson-cont">
              <div
                  v-for="(video, i) in videos"
                  :key="video.index"
                  class="lssn"
              >
                <p class="lssn-title">Video {{ i + 1 }}</p>
                <div class="lesson-body is-flex-desktop is-flex-tablet">
                  <div @click="openVideo(video)" class="lsn-img old">
                    <img class="lsn-wave" src="@/assets/lessons/wave.svg"/>
                    <span class="lsn-img-title">{{ video.title }}</span>
                  </div>
                  <div class="lsn-txt">
                    <p>{{ video.description }}</p>
                    <span @click="openVideo(video)">Discuss this >></span>
                    <!--                      <router-link :to="{ name: 'Lesson1' }">Discuss this >></router-link>-->
                  </div>
                </div>
              </div>
              <div v-if="!videos.length" class="card block">
                <div class="card-content">
                  <p>Coming soon</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Mobilemenu from "@/components/Mobilemenu.vue";
import {auth, db} from "@/firebase";

export default {
  name: "KozHubVideos",
  components: {
    Sidebar,
    Mobilemenu,
  },
  data() {
    return {
      admin: false,
      staff: false,
      loaded: false,
      videos: [],
    };
  },
  async created() {
    const idTokenResult = await auth.currentUser.getIdTokenResult();
    // Confirm the user is an Admin.
    this.admin = !!idTokenResult.claims.admin;
    this.staff = !!idTokenResult.claims.staff;
    this.$bind(
        "user",
        db.collection("users").doc(this.$store.state.userProfile.uid)
    ).then((user) => {
      // user will point to the same property declared in data:
      // this.user === user
      this.loaded = true;
      // console.log(user)
    });
    this.$bind(
        "kozhub",
        db.collection("kozhub")
    ).then((kozhub) => {
      // console.log(kozhub)
      this.videos = kozhub
          .map((x) => ({...x, id: x.id}))
          .sort((a, b) => a.index - b.index);
    });
  },
  // firestore: {
  //   videos: db.collection("kozhub"),
  // },
  computed: {
    activeSub() {
      return this.user.subscription_end_date?.toDate() > new Date();
    },
    // orderedVideos() {
    //   return this.videos
    //       .map((x) => ({...x, id: x.id}))
    //       .sort((a, b) => a.index - b.index);
    // },
  },
  methods: {
    openVideo(video) {
      this.$router.push({
        name: "KozHubVideosVideo",
        params: {video: video.id},
      });
    },
  },
};
</script>

<style scoped lang="scss">
.maincolumn {
  padding: 78px 78px 0 78px;
}

.card {
  flex: 1;
  display: flex;
  transition: all 0.3s ease-in-out;

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    transform: scale(1.02, 1.02);

    &::after {
      opacity: 1;
    }
  }
}

.card-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.has-text-centered.block {
  flex: 1;
}

.toolbar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
  margin-bottom: 75px;

  .dividerbar {
    width: 1px;
    height: 23px;
    background: var(--text-primary-color);
  }

  span {
    &:hover {
      text-decoration-line: underline;
      color: var(--text-secondary-color);
    }

    cursor: pointer;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: var(--text-tertiary-color);
  }
}

.lesson-cont {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.lssn {
  //display: flex;
  .lssn-title {
    margin-bottom: 10px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.1em;
    color: var(--text-primary-color);
  }

  .lesson-body {
    gap: 33px;
    //.lsn-img {
    //  img {
    //    width: auto;
    //    height: auto;
    //    max-width: none;
    //    max-height: none;
    //  }
    //}
    .lsn-img {
      &.old {
        background: var(--var-gradient-lesson);

        .lsn-img-title {
          color: #ddd;
        }
      }

      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .lsn-wave {
        width: 40px;
        height: 10px;
      }

      width: 224px;
      height: 128px;
      background: var(--var-gradient-lesson);
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      text-align: center;
      color: #ffffff;
    }

    .lsn-txt {
      max-width: 500px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p {
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.1em;
        color: var(--text-tertiary-color);
      }

      a,
      span {
        cursor: pointer;
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.1em;
        text-decoration-line: underline;
        color: var(--text-tertiary-color);

        &:hover {
          text-decoration-line: underline;
          color: var(--text-primary-color);
        }
      }
    }
  }
}
</style>
