<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="columns">
        <div class="column is-one-fifth sbar">
          <Sidebar/>
        </div>
        <div v-if="loaded && activeSub" class="column maincolumn">
<!--          <p class="lesson-title">Breakers</p>-->
          <div class="bearish-a">
<!--            <p class="lesson-title">XBT: 29th August 2019 - 4H</p>-->
            <div class="ob-img">
              <img src="@/assets/lessons/lesson3/1.png">
            </div>
          </div>
          <div class="bearish-b">
            <p class="lesson-title">XBT: August 2019 - 1H</p>
            <div class="ob-img">
              <img src="@/assets/lessons/lesson3/2.png">
            </div>
          </div>
          <div class="bearish-c">
            <p class="lesson-title">XBT: August 2019 - 1H</p>
            <div class="ob-img">
              <img src="@/assets/lessons/lesson3/3.png">
            </div>
          </div>
          <div class="bullish-a">
            <p class="lesson-title">XBT: August 2019 - 2H</p>
            <div class="ob-img">
              <img src="@/assets/lessons/lesson3/4.png">
            </div>
            <div class="caption">
              <ol>
                <li>Market Structure Break</li>
                <li>Bullish Orderblock confirmed</li>
                <li>Bullish Orderblock fails to hold and price tests Bearish Breaker</li>
              </ol>
            </div>
          </div>
          <div class="bullish-b">
<!--            <p class="lesson-title">XBT: 15th January 2020 - 1H</p>-->
            <div class="ob-img">
              <img src="@/assets/lessons/lesson3/5.png">
            </div>
            <div class="caption">
              <ol>
                <li>Market Structure Break</li>
                <li>Bullish Orderblock confirmed</li>
                <li>Bullish Orderblock fails to hold and price tests Bearish Breaker</li>
                <li>Entry below breaker with stop above</li>
                <li>Target untapped lows</li>
              </ol>
            </div>
          </div>
          <div class="bullish-c">
<!--            <p class="lesson-title">COMPPERP: July 2021 - 1D</p>-->
            <div class="ob-img">
              <img src="@/assets/lessons/lesson3/6.png">
            </div>
          </div>
          <div class="bullish-c">
            <p class="lesson-title">COMPPERP: July 2021 - 1D</p>
            <div class="ob-img">
              <img src="@/assets/lessons/lesson3/7.png">
            </div>
            <div class="caption">
              <ol>
                <li>Market Structure Break</li>
                <li>Up move before MSB confirms Bearish Orderblock</li>
              </ol>
            </div>
          </div>
          <div class="bullish-c">
            <p class="lesson-title">COMPPERP: July 2021 - 1D</p>
            <div class="ob-img">
              <img src="@/assets/lessons/lesson3/8.png">
            </div>
            <div class="caption">
              <ol>
                <li>Market Structure Break</li>
                <li>Bearish Orderblock confirmed</li>
                <li>Bearish Orderblock fails to hold and price tests Bearish Breaker</li>
              </ol>
            </div>
          </div>
          <div class="bullish-c">
            <p class="lesson-title">COMPPERP: July 2021 - 1D</p>
            <div class="ob-img">
              <img src="@/assets/lessons/lesson3/9.png">
            </div>
            <div class="caption">
              <ol>
                <li>Market Structure Break</li>
                <li>Bearish Orderblock confirmed</li>
                <li>Bearish Orderblock fails to hold and price tests Bearish Breaker</li>
                <li>Entry at breaker with stop below</li>
                <li>Target untapped highs</li>
              </ol>
            </div>
          </div>
          <div class="bullish-c">
            <p class="lesson-title">XBT: April 2020 4H</p>
            <div class="ob-img">
              <img src="@/assets/lessons/lesson3/10.png">
            </div>
            <div class="caption">
              <ol>
                <li>Market Structure Break</li>
                <li>Downside Market Structure Break</li>
                <li>Bearish Orderblock fails to hold</li>
                <li>Entry at breaker with stop below</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Mobilemenu from "@/components/Mobilemenu.vue";
import {auth, db} from "@/firebase";

export default {
  name: "LessonTest",
  components: {
    Sidebar,
  },
  data() {
    return {
      loaded: false,
    }
  },
  async created() {
    this.$bind('user', db.collection('users').doc(this.$store.state.userProfile.uid)).then(user => {
      // console.log(user)
      this.loaded = true;
    })
  },
  computed: {
    activeSub() {
      return this.user.subscription_end_date?.toDate() > new Date();
    },
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.sbar {
  padding-right: 14px;
  border-right: 2px solid rgba(81, 162, 173, 0.5);
}
.maincolumn {
  padding: 78px 78px 0 78px;
}
.card {
  flex: 1;
  display: flex;
  transition: all 0.3s ease-in-out;
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    transition: opacity 0.3s ease-in-out;
  }
  &:hover {
    transform: scale(1.02, 1.02);
    &::after {
      opacity: 1;
    }
  }
}
.card-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.has-text-centered.block {
  flex: 1;
}
.lesson-title {
  margin: 140px 0 120px 0;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: var(--primary-text-color);
}
.orderblocks {
  display: flex;
  justify-content: space-around;
  margin-top: 160px;
  .orderblock {
    &.bearish {
      img {
        padding-top: 100px;
      }
    }
    p {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 300;
      font-size: 40px;
      line-height: 47px;
      /* identical to box height */
      text-align: center;
      color:var(--text-tertiary-color);
    }
  }
}
.ob-img {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.bearish-a, .bearish-b, .bearish-c, .bullish-a, .bullish-b, .bullish-c {
  padding: 100px 0;
  .caption {
    display: flex;
    justify-content: center;
    ol {
      margin-top: 80px;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
      color:var(--text-tertiary-color);
    }
  }
}
</style>
