<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="is-hidden-desktop is-hidden-widescreen">
        <Mobilemenu/>
      </div>
      <div class="columns">
        <div
            class="column is-narrow-desktop is-hidden-mobile is-hidden-touch sbar"
        >
          <Sidebar/>
        </div>
        <div v-if="loaded" class="column maincolumn lessonContainer">
          <div class="is-full-touch is-full-mobile" v-if="activeSub">
            <!-- <div class="toolbar">
                <span class="dividerbar"></span>
                <span @click="setCategory('lessons')">Lessons</span>
                <span @click="setCategory('strategies')">Strategies</span>
                <span @click="setCategory('concepts')">Concepts</span>
                <span class="dividerbar"></span>
              </div> -->
            <div class="toolbar">
              <!--                <span class="dividerbar"></span>-->
              <span @click="setCategory('lessons_v1')"
                    :class="{ 'is-underlined': selected === 'lessons_v1' }">Lessons</span>
              <span @click="setCategory('strategies_v2')" :class="{ 'is-underlined': selected === 'strategies_v2' }"
              >Strategies</span
              >
              <span @click="setCategory('concepts_v3')" :class="{ 'is-underlined': selected === 'concepts_v3' }">Concepts</span>
              <!--                <span class="dividerbar"></span>-->
              <div v-if="admin" class="field is-grouped">
                <router-link
                    :to="{ name: 'EducationPDFLessonsAddLesson' }"
                    class="button is-success"
                >Create Lesson
                </router-link
                >
              </div>
            </div>
          </div>
          <div class="is-flex is-justify-content-space-between block"></div>
          <div v-if="selected === 'lessons'" class="lesson-cont">
            <div class="lssn">
              <p class="lssn-title">Lesson One</p>
              <div class="lesson-body is-flex-desktop is-flex-tablet">
                <div class="lsn-img">
                  <img class="lsn-wave" src="@/assets/lessons/wave.svg"/>
                  <span class="lsn-img-title">Lesson:</span>
                  <span class="lsn-img-title">Supply/Demand</span>
                </div>
                <div class="lsn-txt">
                  <!--                    <p>Nulla vel auctor odio. Nam pharetra turpis non est lacinia sodales. Phasellus tempus felis ipsum, vitae rutrum quam finibus consequat. Integer suscipit in mi consequat laoreet.</p>-->
                  <router-link :to="{ name: 'Lesson1' }"
                  >Discuss this >>
                  </router-link
                  >
                </div>
              </div>
            </div>
            <div class="lssn">
              <p class="lssn-title">Lesson Two</p>
              <div class="lesson-body is-flex-desktop is-flex-tablet">
                <div class="lsn-img">
                  <img class="lsn-wave" src="@/assets/lessons/wave.svg"/>
                  <span class="lsn-img-title">Lesson:</span>
                  <span class="lsn-img-title">Market Structure</span>
                </div>
                <div class="lsn-txt">
                  <!--                    <p>Nulla vel auctor odio. Nam pharetra turpis non est lacinia sodales. Phasellus tempus felis ipsum, vitae rutrum quam finibus consequat. Integer suscipit in mi consequat laoreet.</p>-->
                  <router-link :to="{ name: 'Lesson2' }"
                  >Discuss this >>
                  </router-link
                  >
                </div>
              </div>
            </div>
            <div class="lssn">
              <p class="lssn-title">Lesson Three</p>
              <div class="lesson-body is-flex-desktop is-flex-tablet">
                <div class="lsn-img">
                  <img class="lsn-wave" src="@/assets/lessons/wave.svg"/>
                  <span class="lsn-img-title">Lesson:</span>
                  <span class="lsn-img-title">Breakers</span>
                </div>
                <div class="lsn-txt">
                  <!--                    <p>Nulla vel auctor odio. Nam pharetra turpis non est lacinia sodales. Phasellus tempus felis ipsum, vitae rutrum quam finibus consequat. Integer suscipit in mi consequat laoreet.</p>-->
                  <router-link :to="{ name: 'Lesson3' }"
                  >Discuss this >>
                  </router-link
                  >
                </div>
              </div>
            </div>
            <!--              <div class="lssn">-->
            <!--                <p class="lssn-title">Lesson Four</p>-->
            <!--                <div class="lesson-body is-flex-desktop is-flex-tablet">-->
            <!--                  <div class="lsn-img">-->
            <!--                    <img class="lsn-wave" src="@/assets/lessons/wave.svg">-->
            <!--                    <span class="lsn-img-title">Lesson:</span>-->
            <!--                    <span class="lsn-img-title">Supply/Demand</span>-->
            <!--                  </div>-->
            <!--                  <div class="lsn-txt">-->
            <!--&lt;!&ndash;                    <p>Nulla vel auctor odio. Nam pharetra turpis non est lacinia sodales. Phasellus tempus felis ipsum, vitae rutrum quam finibus consequat. Integer suscipit in mi consequat laoreet.</p>&ndash;&gt;-->
            <!--                    <router-link :to="{ name: 'Lesson4' }">Discuss this >></router-link>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <div class="lssn">
              <p class="lssn-title">Lesson Five</p>
              <div class="lesson-body is-flex-desktop is-flex-tablet">
                <div class="lsn-img">
                  <img class="lsn-wave" src="@/assets/lessons/wave.svg"/>
                  <span class="lsn-img-title">Lesson:</span>
                  <span class="lsn-img-title">Fair Value Gaps</span>
                </div>
                <div class="lsn-txt">
                  <!--                    <p>Nulla vel auctor odio. Nam pharetra turpis non est lacinia sodales. Phasellus tempus felis ipsum, vitae rutrum quam finibus consequat. Integer suscipit in mi consequat laoreet.</p>-->
                  <router-link :to="{ name: 'Lesson5' }"
                  >Discuss this >>
                  </router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-if="selected === 'strategies'" class="lesson-cont">
            <div class="lssn">
              <p class="lssn-title">Strategy One</p>
              <div class="lesson-body is-flex-desktop is-flex-tablet">
                <div class="lsn-img">
                  <img class="lsn-wave" src="@/assets/lessons/wave.svg"/>
                  <span class="lsn-img-title">Strategy:</span>
                  <span class="lsn-img-title">Breakers</span>
                </div>
                <div class="lsn-txt">
                  <!--                    <p>Nulla vel auctor odio. Nam pharetra turpis non est lacinia sodales. Phasellus tempus felis ipsum, vitae rutrum quam finibus consequat. Integer suscipit in mi consequat laoreet.</p>-->
                  <router-link :to="{ name: 'Lesson6' }"
                  >Discuss this >>
                  </router-link
                  >
                </div>
              </div>
            </div>
            <div class="lssn">
              <p class="lssn-title">Strategy Two</p>
              <div class="lesson-body is-flex-desktop is-flex-tablet">
                <div class="lsn-img">
                  <img class="lsn-wave" src="@/assets/lessons/wave.svg"/>
                  <span class="lsn-img-title">Strategy:</span>
                  <span class="lsn-img-title">Ranges + Supply/Demand</span>
                </div>
                <div class="lsn-txt">
                  <!--                    <p>Nulla vel auctor odio. Nam pharetra turpis non est lacinia sodales. Phasellus tempus felis ipsum, vitae rutrum quam finibus consequat. Integer suscipit in mi consequat laoreet.</p>-->
                  <router-link :to="{ name: 'Lesson7' }"
                  >Discuss this >>
                  </router-link
                  >
                </div>
              </div>
            </div>
            <div class="lssn">
              <p class="lssn-title">Strategy Three</p>
              <div class="lesson-body is-flex-desktop is-flex-tablet">
                <div class="lsn-img">
                  <img class="lsn-wave" src="@/assets/lessons/wave.svg"/>
                  <span class="lsn-img-title">Strategy:</span>
                  <span class="lsn-img-title">Supply/Demand</span>
                </div>
                <div class="lsn-txt">
                  <!--                    <p>Nulla vel auctor odio. Nam pharetra turpis non est lacinia sodales. Phasellus tempus felis ipsum, vitae rutrum quam finibus consequat. Integer suscipit in mi consequat laoreet.</p>-->
                  <router-link :to="{ name: 'Lesson8' }"
                  >Discuss this >>
                  </router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-if="selected === 'concepts'" class="lesson-cont">
            <div class="lssn">
              <p class="lssn-title">Concept One</p>
              <div class="lesson-body is-flex-desktop is-flex-tablet">
                <div class="lsn-img">
                  <img class="lsn-wave" src="@/assets/lessons/wave.svg"/>
                  <span class="lsn-img-title">Concept:</span>
                  <span class="lsn-img-title">Monday's Range</span>
                </div>
                <div class="lsn-txt">
                  <!--                    <p>Nulla vel auctor odio. Nam pharetra turpis non est lacinia sodales. Phasellus tempus felis ipsum, vitae rutrum quam finibus consequat. Integer suscipit in mi consequat laoreet.</p>-->
                  <router-link :to="{ name: 'Lesson9' }"
                  >Discuss this >>
                  </router-link
                  >
                </div>
              </div>
            </div>
            <div class="lssn">
              <p class="lssn-title">Concept Two</p>
              <div class="lesson-body is-flex-desktop is-flex-tablet">
                <div class="lsn-img">
                  <img class="lsn-wave" src="@/assets/lessons/wave.svg"/>
                  <span class="lsn-img-title">Concept:</span>
                  <span class="lsn-img-title">TPO Market Profiling</span>
                </div>
                <div class="lsn-txt">
                  <!--                    <p>Nulla vel auctor odio. Nam pharetra turpis non est lacinia sodales. Phasellus tempus felis ipsum, vitae rutrum quam finibus consequat. Integer suscipit in mi consequat laoreet.</p>-->
                  <router-link :to="{ name: 'Lesson10' }"
                  >Discuss this >>
                  </router-link
                  >
                </div>
              </div>
            </div>
            <!--              <div class="lssn">-->
            <!--                <p class="lssn-title">Concept Three</p>-->
            <!--                <div class="lesson-body is-flex-desktop is-flex-tablet">-->
            <!--                  <div class="lsn-img">-->
            <!--                    <img class="lsn-wave" src="@/assets/lessons/wave.svg">-->
            <!--                    <span class="lsn-img-title">aaaaaaaaa</span>-->
            <!--                  </div>-->
            <!--                  <div class="lsn-txt">-->
            <!--&lt;!&ndash;                    <p>Nulla vel auctor odio. Nam pharetra turpis non est lacinia sodales. Phasellus tempus felis ipsum, vitae rutrum quam finibus consequat. Integer suscipit in mi consequat laoreet.</p>&ndash;&gt;-->
            <!--                    <router-link :to="{ name: 'Lesson11' }">Discuss this >></router-link>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <div class="lssn">
              <p class="lssn-title">Concept Four</p>
              <div class="lesson-body is-flex-desktop is-flex-tablet">
                <div class="lsn-img">
                  <img class="lsn-wave" src="@/assets/lessons/wave.svg"/>
                  <span class="lsn-img-title">Concept:</span>
                  <span class="lsn-img-title">Trading Sessions</span>
                </div>
                <div class="lsn-txt">
                  <!--                    <p>Nulla vel auctor odio. Nam pharetra turpis non est lacinia sodales. Phasellus tempus felis ipsum, vitae rutrum quam finibus consequat. Integer suscipit in mi consequat laoreet.</p>-->
                  <router-link :to="{ name: 'Lesson12' }"
                  >Discuss this >>
                  </router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-if="oldCategorySelected" class="block">
            <div class="lesson-cont">
              <div
                  v-for="(lesson, i) in getSelectedLessons()"
                  :key="lesson.index"
                  class="lssn"
              >
                <p class="lssn-title">{{ lesson.type }} {{ i + 1 }} <span
                    v-if="lesson.type !== 'Lesson'"> - Lesson {{ lesson.index }}</span></p>
                <div class="lesson-body is-flex-desktop is-flex-tablet">
                  <div @click="openLesson(lesson)" class="lsn-img old">
                    <img class="lsn-wave" src="@/assets/lessons/wave.svg"/>
                    <span class="lsn-img-title">{{ lesson.title }}</span>
                  </div>
                  <div class="lsn-txt">
                    <p>{{ lesson.description }}</p>
                    <span @click="openLesson(lesson)">Discuss this >></span>
                    <!--                      <router-link :to="{ name: 'Lesson1' }">Discuss this >></router-link>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Mobilemenu from "@/components/Mobilemenu.vue";
import {auth, db} from "@/firebase";

export default {
  name: "EducationPDFLessons",
  components: {
    Sidebar,
    Mobilemenu,
  },
  data() {
    return {
      admin: false,
      selected: "lessons_v1",
      loaded: false,
      categories: [
        {
          name: "RektProof Course Lessons 1-5",
          index: 1,
          id: 1,
        },
        {
          name: "RektProof Trading Strategies 6-8",
          index: 2,
          id: 2,
        },
        {
          name: "RektProof Trading Concepts",
          index: 3,
          id: 3,
        },
      ],
      lessons: [],
    };
  },
  async created() {
    const idTokenResult = await auth.currentUser.getIdTokenResult();
    // Confirm the user is an Admin.
    this.admin = !!idTokenResult.claims.admin;
    this.$bind(
        "user",
        db.collection("users").doc(this.$store.state.userProfile.uid)
    ).then((user) => {
      // user will point to the same property declared in data:
      // this.user === user
      this.loaded = true;
      // console.log(user)
    });
  },
  firestore: {
    lessons: db.collection("lessons"),
  },
  computed: {
    activeSub() {
      return this.user.subscription_end_date?.toDate() > new Date();
    },
    // categories() {
    //   return this.lessons
    //       // .map(x => x.category)
    //       .filter((value, index, self) => self.findIndex((m) => m.category === value.category ) === index)
    // },
    orderedCategories() {
      return this.categories
          .map((x) => ({...x, id: x.id}))
          .sort((a, b) => a.index - b.index);
    },
    oldCategorySelected() {
      return ["lessons_v1", "strategies_v2", "concepts_v3"].includes(
          this.selected
      );
    },
    selectedOldLessons() {
      if (this.selected === "lessons_v1") {
        return this.orderedCategories[0];
      } else if (this.selected === "strategies_v2") {
        return this.orderedCategories[1];
      } else if (this.selected === "concepts_v3") {
        return this.orderedCategories[2];
      }
    },
    lessonsOld() {
      return this.lessons
          .filter((x) => x.type === "Lesson")
          .filter((x) => x.v1);
    },
    strategiesOld() {
      return this.lessons
          .filter((x) => x.type === "Strategy")
          .filter((x) => x.v1);
    },
    conceptsOld() {
      return this.lessons
          .filter((x) => x.type === "Concept")
          .filter((x) => x.v1);
    },
    orderedLessons() {
      return this.lessons
          .map((x) => ({...x, id: x.id}))
          .sort((a, b) => a.index - b.index);
    },
  },
  methods: {
    setCategory(category) {
      this.selected = category;
    },
    getLessons(category) {
      return this.orderedLessons.filter(
          (lesson) => lesson.category === category
      );
    },
    getSelectedLessons() {
      if (this.selected === "lessons_v1") {
        return this.lessonsOld.sort((a, b) => a.index - b.index);
      } else if (this.selected === "strategies_v2") {
        return this.strategiesOld.sort((a, b) => a.index - b.index);
      } else if (this.selected === "concepts_v3") {
        return this.conceptsOld.sort((a, b) => a.index - b.index);
      }
    },
    openLesson(lesson) {
      this.$router.push({
        name: "EducationPDFLessonsLesson",
        params: {lesson: lesson.id},
      });
    },
    addLesson() {
      this.$router.push({name: "EducationPDFLessons"});
    },
  },
};
</script>

<style scoped lang="scss">
.maincolumn {
  padding: 78px 78px 0 78px;
}

.card {
  flex: 1;
  display: flex;
  transition: all 0.3s ease-in-out;

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    transform: scale(1.02, 1.02);

    &::after {
      opacity: 1;
    }
  }
}

.card-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.has-text-centered.block {
  flex: 1;
}

.toolbar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
  margin-bottom: 75px;

  .dividerbar {
    width: 1px;
    height: 23px;
    background: var(--text-primary-color);
  }

  span {
    &:hover {
      text-decoration-line: underline;
      color: var(--text-secondary-color);
    }

    cursor: pointer;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: var(--text-tertiary-color);
  }
}

.lesson-cont {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.lssn {
  //display: flex;
  .lssn-title {
    margin-bottom: 10px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.1em;
    color: var(--text-primary-color);
  }

  .lesson-body {
    gap: 33px;
    //.lsn-img {
    //  img {
    //    width: auto;
    //    height: auto;
    //    max-width: none;
    //    max-height: none;
    //  }
    //}
    .lsn-img {
      &.old {
        background: var(--var-gradient-lesson);

        .lsn-img-title {
          color: #ddd;
        }
      }

      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .lsn-wave {
        width: 40px;
        height: 10px;
      }

      width: 224px;
      height: 128px;
      background: var(--var-gradient-lesson);
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      text-align: center;
      color: #ffffff;
    }

    .lsn-txt {
      max-width: 500px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p {
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.1em;
        color: var(--text-tertiary-color);
      }

      a,
      span {
        cursor: pointer;
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.1em;
        text-decoration-line: underline;
        color: var(--text-tertiary-color);

        &:hover {
          text-decoration-line: underline;
          color: var(--text-primary-color);
        }
      }
    }
  }
}
</style>
