<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="columns">
        <div class="column is-one-fifth sbar">
          <Sidebar/>
        </div>
        <div v-if="loaded && activeSub" class="column maincolumn">
          <p class="lesson-title">Supply and Demand: Orderblocks</p>
          <div class="orderblocks">
            <div class="orderblock bearish">
              <p class="">Bearish Orderblock</p>
              <div class="ob-img">
                <img src="@/assets/sd-ob1.svg">
              </div>
            </div>
            <div class="orderblock bullish">
              <p class="">Bullish Orderblock</p>
              <div class="ob-img">
                <img src="@/assets/sd-ob2.svg">
              </div>
            </div>
          </div>
          <div class="bearish-a">
            <p class="lesson-title">XBT: 29th August 2019 - 4H</p>
            <div class="ob-img">
              <img src="@/assets/sd-b-a-1.jpg">
            </div>
            <div class="caption">
              <ol>
                <li>Market Structure break</li>
                <li>Up move before MSB</li>
                <li>Enter at Orderblock</li>
                <li>Target Swing Low/Stop above Orderblock</li>
              </ol>
            </div>
          </div>
          <div class="bearish-b">
            <p class="lesson-title">XBT: 20th December 2019 - 4H</p>
            <div class="ob-img">
              <img src="@/assets/sd-b-b-1.jpg">
            </div>
            <div class="caption">
              <ol>
                <li>Market Structure break</li>
                <li>Up move before MSB</li>
                <li>Enter at 0.705 Fib Retracement</li>
                <li>Target Swing Low/Stop above Orderblock</li>
              </ol>
            </div>
          </div>
          <div class="bearish-c">
            <p class="lesson-title">XBT: 10th June 2020 - 1D</p>
            <div class="ob-img">
              <img src="@/assets/sd-b-c-1.jpg">
            </div>
            <div class="caption">
              <ol>
                <li>Market Structure break</li>
                <li>Up move before MSB</li>
                <li>Enter at 0.705 Fib Retracement</li>
                <li>Target Swing Low/Stop above Orderblock</li>
              </ol>
            </div>
          </div>

          <div class="bullish-a">
            <p class="lesson-title">XBT: 10th August 2019 - 1D</p>
            <div class="ob-img">
              <img src="@/assets/sd-bb-a-1.jpg">
            </div>
            <div class="caption">
              <ol>
                <li>Market Structure break</li>
                <li>Down move before MSB</li>
                <li>Enter at top of Orderblock</li>
                <li>Target Swing High/Stop below Orderblock</li>
              </ol>
            </div>
          </div>
          <div class="bullish-b">
            <p class="lesson-title">XBT: 15th January 2020 - 1H</p>
            <div class="ob-img">
              <img src="@/assets/sd-bb-b-1.jpg">
            </div>
            <div class="caption">
              <ol>
                <li>MSB</li>
                <li>Down move before MSB</li>
                <li>Enter at 0.705 Fib Retracement</li>
                <li>Target Swing High/Stop below Orderblock</li>
              </ol>
            </div>
          </div>
          <div class="bullish-c">
            <p class="lesson-title">XBT: 10th April 2019 - 4H</p>
            <div class="ob-img">
              <img src="@/assets/sd-bb-c-1.jpg">
            </div>
            <div class="caption">
              <ol>
                <li>Price runs previous low</li>
                <li>Market Structure Break</li>
                <li>Down move before MSB</li>
                <li>Enter at 0.705 Fib Retracement</li>
                <li>Target Swing High/Stop below Orderblock</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Mobilemenu from "@/components/Mobilemenu.vue";
import {auth, db} from "@/firebase";

export default {
  name: "LessonTest",
  components: {
    Sidebar,
  },
  data() {
    return {
      loaded: false,
    }
  },
  async created() {
    this.$bind('user', db.collection('users').doc(this.$store.state.userProfile.uid)).then(user => {
      // console.log(user)
      this.loaded = true;
    })
  },
  computed: {
    activeSub() {
      return this.user.subscription_end_date?.toDate() > new Date();
    },
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.sbar {
  padding-right: 14px;
  border-right: 2px solid rgba(81, 162, 173, 0.5);
}
.maincolumn {
  padding: 78px 78px 0 78px;
}
.card {
  flex: 1;
  display: flex;
  transition: all 0.3s ease-in-out;
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    transition: opacity 0.3s ease-in-out;
  }
  &:hover {
    transform: scale(1.02, 1.02);
    &::after {
      opacity: 1;
    }
  }
}
.card-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.has-text-centered.block {
  flex: 1;
}
.lesson-title {
  margin: 140px 0 120px 0;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: var(--navigation-bar-text);
}
.orderblocks {
  display: flex;
  justify-content: space-around;
  margin-top: 160px;
  .orderblock {
    &.bearish {
      img {
        padding-top: 100px;
      }
    }
    p {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 300;
      font-size: 40px;
      line-height: 47px;
      /* identical to box height */
      text-align: center;
      color:var(--text-tertiary-color);
    }
  }
}
.ob-img {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.bearish-a, .bearish-b, .bearish-c, .bullish-a, .bullish-b, .bullish-c {
  padding: 100px 0;
  .caption {
    display: flex;
    justify-content: center;
    ol {
      margin-top: 80px;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
      color:var(--text-tertiary-color);
    }
  }
}
</style>
